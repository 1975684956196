exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-author-authors-json-slug-js": () => import("./../../../src/pages/author/{AuthorsJson.slug}.js" /* webpackChunkName: "component---src-pages-author-authors-json-slug-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-category-categories-json-slug-js": () => import("./../../../src/pages/category/{CategoriesJson.slug}.js" /* webpackChunkName: "component---src-pages-category-categories-json-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-rant-js": () => import("./../../../src/templates/rant.js" /* webpackChunkName: "component---src-templates-rant-js" */)
}

